import styled from 'styled-components';

const MyContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: ${({ theme }) => theme.colors.neutral['10']};

    > div {
        box-shadow: rgba(59, 118, 160, 0.1) 0px 16px 24px 0px, rgba(59, 118, 160, 0.05) 0px 24px 80px 0px;
        background: #ffffff;
        border-radius: 0.8rem;
        padding: 4.8rem;
    }
`;

export default MyContainer;
